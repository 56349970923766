<template>
	<div class="page">
		<gc-header headerData="deviceTypeManage" class="type-manage-header">
			<template #ops>
				<el-button
					type="primary"
					@click="
						() => {
							visible = true
							flagDialog = 'add'
						}
					"
					v-if="$has('monitor:device:type:maintain')"
				>
					新增设备类型
				</el-button>
			</template>
		</gc-header>
		<div class="table-show page-main">
			<gc-table
				:columns="columns"
				:table-data="tableData"
				:border="true"
				:showPage="true"
				:total="page.total"
				:page-size="page.size"
				:current-page.sync="page.current"
				@size-change="handleSizeChange"
				@current-page-change="currentPageChange"
			></gc-table>
		</div>
		<!-- 新增/修改/详情 -->
		<device-type-dialog
			:visible.sync="visible"
			:flag.sync="flagDialog"
			:device-type-id="deviceTypeId"
			@update-list="getDeviceTypeList(1)"
		></device-type-dialog>
	</div>
</template>

<script>
import deviceTypeDialog from './DeviceTypeDialog.vue'
import { apiGetDeviceTypeList } from '@/apis/type.api.js'

export default {
	name: 'typeManage',
	props: {},
	components: { deviceTypeDialog },
	data() {
		return {
			columns: [
				{
					key: 'deviceTypeCode',
					name: '设备类型编号',
				},
				{
					key: 'firstCategory',
					name: '设备大类',
				},
				{
					key: 'secondCategory',
					name: '设备小类',
				},
				{
					key: 'deviceTypeName',
					name: '设备类型名称',
				},
				{
					key: 'model',
					name: '型号',
				},
				{
					key: 'manufacturerName',
					name: '厂商名称',
				},
				{
					key: 'ops',
					name: '操作',
					render: (h, row) => {
						//  自定义表格内容展示
						return h('div', {}, [
							h(
								'el-button',
								{
									props: {
										type: 'text',
										size: 'medium',
									},
									on: {
										click: () => {
											this.getMeterTypeDetail('detail', row)
										},
									},
								},
								'查看详情',
							),
							this.$has('monitor:deviceType:update')
								? h(
										'el-button',
										{
											props: {
												type: 'text',
												size: 'medium',
											},
											on: {
												click: () => {
													this.getMeterTypeDetail('edit', row)
												},
											},
										},
										'修改',
								  )
								: '',
						])
					},
					fixed: 'right',
				},
			],
			tableData: [],
			page: {
				size: 20,
				total: 0,
				current: 1,
			},
			visible: false, //弹窗
			flagDialog: 'add', //弹窗标识 add-新增 edit-修改 detail-详情
			deviceTypeId: null, //当前选中的设备类型id
		}
	},
	mounted() {
		this.getDeviceTypeList(1)
	},
	methods: {
		// 查看设备详情
		getMeterTypeDetail(flag, row) {
			this.flagDialog = flag
			this.deviceTypeId = row.deviceTypeId
			this.visible = true
		},
		handleSizeChange(size) {
			this.page.size = size
			this.getDeviceTypeList(1)
		},
		//设备类型列表查询
		getDeviceTypeList(current) {
			this.page.current = current
			apiGetDeviceTypeList({
				size: this.page.size,
				current: this.page.current,
			}).then((res = {}) => {
				this.page.total = res.total
				this.tableData = res.records || []
			})
		},
		// 翻页
		currentPageChange(currentPage) {
			this.getDeviceTypeList(currentPage)
		},
	},
}
</script>
<style lang="scss" scoped>
.type-manage-header {
	@include base-button($width: 120px);
}
.table-show {
	flex-direction: column;
	padding: 20px 24px 0 24px;
	height: 0;
}
</style>
